import React from 'react';
import styled from 'styled-components';

interface Props {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

export default function TwoColumnLayout({ leftContent, rightContent }: Props) {
  return (
    <Container>
      <LeftColumn>{leftContent}</LeftColumn>
      <RightColumn>{rightContent}</RightColumn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
`;

const LeftColumn = styled.div`
  width: 200px;
  @media (max-width: 846px) {
    display: none;
  }
`;

const RightColumn = styled.div`
  width: 920px;
  max-width: 920px;
  flex-grow: 1;
`;
