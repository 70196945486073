import React from 'react'
import Styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

export default function Container({children}: Props) {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = Styled.div`
	position: relative;
	min-height: 50vh;
	padding: 32px 24px 80px;
	@media (max-width: 640px) {
		padding: 24px 16px 40px;
	}
`
