import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'

import { useAuth } from '../../contexts/AuthContext'
import { isMobile } from 'react-device-detect'
import { logger } from '../../utils/logger'
import { getMe } from 'apis/me'
import { Me } from 'apis/response_types'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import {
  getScreenName,
  getUserName,
  getCurrentDoorName
} from '../../modules/me'
export interface BreadCrumb {
  to?: string
  name: string
}
interface Props {
  breadcrumbsList: BreadCrumb[]
  // 投稿ページの場合はpostID指定する
  postId?: string
  className?: string
  style?: React.CSSProperties
  pcOnly?: boolean
  spOnly?: boolean 
}

/**
  @deprecated
 */
export default function BreadCrumbs(props: Props) {
  const { breadcrumbsList, postId, className, style, pcOnly, spOnly } = props
  const { getCurrentUsersCognitoSub } = useAuth()

  const [isOwner, setIsOwner] = useState(false)
  const path = window.location.pathname
  const userRegs = [
    /^\/account\//,
    /^\/management\/door\/subscribing/,
    /^\/notifications/
  ]
  const staffManagementReg = /^\/door\/([a-zA-Z0-9_]+)\/management\//
  const managementRegs = [/^\/management\//, staffManagementReg]
  const screenName = useSelector((state: RootState) => getScreenName(state))
  const userName = useSelector((state: RootState) => getUserName(state))
  const doorName = useSelector((state: RootState) => getCurrentDoorName(state))
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cognitoSub = await getCurrentUsersCognitoSub()
        // パンくずリストに自分以外の名前や投稿名を表示するパターンはなくなった想定
        if (postId !== undefined) {
        } else {
          if (cognitoSub) {
            setIsOwner(true)
          }
        }
      } catch (err) {
        logger.debug('ERROR IN fetchData: ', err)
      } finally {
      }
    }
    fetchData()
  }, [])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    // クリーンアップ
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const parentItems = (): BreadCrumb[] => {
    if (userRegs.filter((r) => r.test(path)).length !== 0) {
      const truncatedUserName =
        userName.length > 20 ? userName.substring(0, 20) + '...' : userName
      return [{ to: `/door/${screenName}`, name: truncatedUserName }]
    } else if (managementRegs.filter((r) => r.test(path)).length !== 0) {
      const result = staffManagementReg.exec(path)
      const salonId = result && result[1] ? result[1] : screenName
      const truncatedDoorName =
        doorName.length > 20 ? doorName.substring(0, 20) + '...' : doorName
      return [{
        to: `/door/${salonId}`,
        name: isMobile ? 'サロントップ' : truncatedDoorName
      }]
    } else if (path.includes('/doors/')) {
      const match = path.match(/\/doors\/([a-zA-Z0-9_]+)/)
      const salonId = match ? match[1] : ''
      return [
        { to: '/', name: 'YOORオンラインサロン トップ' },
        { to: `/door/${salonId}`, name: `${salonId}` }
      ]
    } else {
      return [{ to: '/', name: isMobile ? 'トップ' : 'YOORオンラインサロン トップ' }]
    }
  }

  // 表示条件
  if (pcOnly && windowWidth < 641) {
    return null
  }
  if (spOnly && windowWidth >= 641) {
    return null
  }

  return (
    <Breadcrumbs className={className} style={style} pcOnly={pcOnly} spOnly={spOnly}>
      <div className="inner">
      <ul>
        {[...parentItems(), ...breadcrumbsList].map(
          (item: BreadCrumb, index: number) => {
            return (
              <li key={item.name}>
                {item.to ? (
                  <Link to={item.to}>
                    {(userRegs.filter((r) => r.test(path)).length !== 0 ||
                      managementRegs.filter((r) => r.test(path)).length !== 0) &&
                      index === 0 && <HomeIcon />}
                    <span>{item.name}</span>
                  </Link>
                ) : (
                  <span>{item.name}</span>
                )}
              </li>
            )
          }
        )}
      </ul>
      </div>
    </Breadcrumbs>
  )
}

const Breadcrumbs = styled.div<{ pcOnly?: boolean; spOnly?: boolean }>`
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  margin: 0 auto 24px;
  color: #888;
  font-size: 11px;
  overflow-x: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  ${(props) =>
    props.pcOnly &&
    `
    @media (max-width: 640px) {
      display: none;
    }
  `}

  ${(props) =>
    props.spOnly &&
    `
    @media (min-width: 641px) {
      display: none;
    }
  `}

  @media (max-width: 640px) {
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .inner {
    display: flex;
    align-items: center;
    @media (max-width: 640px) {
      width: 100%;
    }
  }
  ul {
    display: flex;
    align-items: center;
  }
  li {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    svg {
      vertical-align: -5px;
    }
    &:after {
      display: inline-block;
      width: 16px;
      text-align: center;
      content: ' > ';
    }
    &:last-child {
      &:after {
        content: '';
      }
      a {
        color: #333;
      }
    }
  }
  a {
    color: #45b5f5;
    &:hover {
      color: #45b5f5;
    }
  }
  .material-icons {
    font-size: 14px;
    width: 14px;
  }
`
