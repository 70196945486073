import styles from 'components/landingpage/assets/TopPage.module.css'

import React, { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import { Auth } from 'aws-amplify'
import DefaultUserIcon from 'images/default_user_icon.jpg'
import { useAuth } from 'contexts/AuthContext'
import { getMe } from 'apis/me'
import { logger } from 'utils/logger'
import meModules, { getCognitoId, getIsSignin } from '../modules/me'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import top_banner_pc_01 from 'components/landingpage/assets/top_banner_pc_01.webp'
import top_banner_pc_02 from 'components/landingpage/assets/top_banner_pc_02.webp'
import top_banner_pc_03 from 'components/landingpage/assets/top_banner_pc_03.webp'
import top_banner_pc_04 from 'components/landingpage/assets/top_banner_pc_04.webp'
import logo from 'components/landingpage/assets/logo.webp'
import icon_notice from 'components/landingpage/assets/icon_notice_sp.svg'
import icon_mypage from 'components/landingpage/assets/icon_mypage_sp.svg'
import icon_download2 from 'components/landingpage/assets/icon_download2.svg'

import { getAllKeywordsV1Api } from 'apis/KeywordsV1'
import { Keyword } from 'apis/response_types'
import UserMenu from 'components/common/UserMenu'
import { setRef } from '@material-ui/core'
import { getDoorCustomize } from 'modules/door'
import alertModules from 'modules/alert'

export const spacesReg = /^[\s\u3000]+$/

const banners = [
  {
    href: 'https://yoor.jp/guideline',
    img: top_banner_pc_01,
    name: 'GUIDELINE'
  },
  {
    href: 'https://releasenotes.yoor.jp/#futureplans',
    img: top_banner_pc_02,
    name: 'UPDATE'
  },
  {
    href: '/beginner',
    img: top_banner_pc_03,
    name: 'BEGINNER'
  },
  {
    href: 'https://blog.yoor.jp/',
    img: top_banner_pc_04,
    name: 'MEDIA'
  }
]

interface RootNaviViewProps {
  onButtonClick: () => void
  isActive: boolean
}

export default function RootNaviView({
  onButtonClick,
  isActive
}: RootNaviViewProps) {
  const dispatch = useDispatch()
  const cognitoId = useSelector((state: RootState) => getCognitoId(state))
  const isSignin = useSelector((state: RootState) => getIsSignin(state))
  const spMenuRef = useRef<HTMLElement | null>(null)
  const isFixedStyle = styles['is-fixed']

  const { signOut, getCurrentUsersCognitoSub } = useAuth()
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false)
  const [isOpenSpMenu, setIsOpenSpMenu] = useState<boolean>(false)
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false)
  // 検索用キーワード　自由入力
  const [keyword, setKeyword] = useState<string>('')
  // カテゴリキーワード
  const [popularKeywords, setPopularKeywords] = useState<Array<Keyword>>([])
  const [draw, setDraw] = useState(false)

  const doorCustomize = useSelector((state: RootState) =>
    getDoorCustomize(state)
  )

  const history = useHistory()

  useEffect(() => {
    const initPage = async () => {
      // Promise の Uncaught エラー回避
      try {
        const cognitoSub = await getCurrentUsersCognitoSub()
        if (cognitoSub) {
          dispatch(meModules.actions.setCognitoId(cognitoSub))
        } else {
          dispatch(meModules.actions.setCognitoId(''))
        }

        // ヘッダー表示用キーワード取得
        const res = await getAllKeywordsV1Api()

        setPopularKeywords(res.slice(0, 15))
      } catch (error) {
        logger.debug(error)
      }
    }
    initPage()

    const listen = history.listen(async () => {
      try {
        if (isSignin) {
          // サインイン状態の場合は、パスが移動するたびに認証状態を確認する
          const user = await Auth.currentAuthenticatedUser()
          // 認証状態を取得するため、現在のユーザー情報を取得するAPIを実行する
          await Auth.userAttributes(user)
        }
      } catch (err) {
        // 未認証だとエラーがでるため、サインアウト処理を実行する
        dispatch(meModules.actions.signOut())
        await signOut()
      }
    })
    return () => {
      listen()
    }
  }, [])

  useEffect(() => {
    const getUserInfo = async () => {
      if (cognitoId !== '') {
        try {
          const response = await getMe()
          const me = response.data
          dispatch(meModules.actions.setHasDoor(me.has_door))

          dispatch(
            meModules.actions.setScreenName(me.user_public.twitter_screen_name)
          )
          dispatch(
            meModules.actions.setUserName(me.user_public.door_screen_name)
          )
          dispatch(meModules.actions.setEmail(me.user.email))
          dispatch(meModules.actions.setChanneltalkId(me.user.channeltalk_id))
          dispatch(
            meModules.actions.setUserIcon(
              me.user_public.icon_image_url !== ''
                ? me.user_public.icon_image_url
                : DefaultUserIcon
            )
          )
          dispatch(meModules.actions.setIsSignin(true))
          dispatch(
            meModules.actions.setAcceptedPrivacyPolicy(
              me.user.accepted_privacy_policy
            )
          )
        } catch (error) {
          await signOut()
          dispatch(meModules.actions.signOut())
        }
      }
    }
    getUserInfo()
  }, [cognitoId])

  useEffect(() => {
    if (isOpenSearch || isOpenSpMenu) {
      document.body.classList.add(isFixedStyle)
    } else {
      document.body.classList.remove(isFixedStyle)
    }
    return () => {
      document.body.classList.remove(isFixedStyle)
    }
  }, [isOpenSearch, isOpenSpMenu])

  const isKeywordBlank = () => {
    if (keyword === '' || spacesReg.test(keyword)) {
      dispatch(
        alertModules.actions.setAlert({
          text: 'キーワードは必須です',
          severity: 'error'
        })
      )
      return true
    } else {
      return false
    }
  }

  const handleSearchEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return
    if (isKeywordBlank()) return
    closeMenu()
    history.push(`/search?keyword=${encodeURIComponent(keyword)}`)
  }

  const closeMenu = () => {
    setIsOpenSearch(false)
    setIsOpenSpMenu(false)
  }

  const location = useLocation()
  const currentPath = location.pathname + location.search + location.hash
  const redirectPathAfterSignIn: string = (() => {
    if (location.pathname === '/') {
      return '/mypage'
    } else {
      return currentPath
    }
  })()

  return (
    <React.Fragment>
      <header
        className={`${styles.menu} ${
          isHeaderVisible ? styles['is-bg-color'] : ''
        } ${isSignin ? styles['is-login'] : ''}`}
      >
        <nav className={`${styles['menu-nav']}`}>
          <div className={`${styles['menu-logo-box']}`}>
            {!doorCustomize && (
              <>
                <Link to="/" className={`${styles['menu-logo']}`}>
                  <img
                    src={logo}
                    className={`${styles['menu-logo-image']}`}
                    alt="YOOR"
                  />
                </Link>
              </>
            )}
          </div>

          {!['/signin', '/signup'].includes(location.pathname) && (
            <div className={`${styles['menu-button-box-sp']}`}>
              <ul className={`${styles['menu-list-sp']}`}>
                <li
                  className={`${styles['menu-list-item-sp']} ${styles['menu-login-item']}`}
                >
                  <div className={`${styles['menu-search-wrap']}`}>
                    {!doorCustomize && (
                      <div
                        className={`${styles['input']} ${styles['input-search-wrap']} ${styles['is-noicon']}`}
                      >
                        <input
                          className={`${styles['input']} ${styles['input-search']}`}
                          placeholder="サロンを検索"
                          onClick={() => {
                            setIsOpenSearch(!isOpenSearch)
                          }}
                          value={keyword}
                          onChange={(e) => {
                            setKeyword(e.target.value)
                          }}
                          onKeyDown={handleSearchEnter}
                        />
                        <div
                          className={`${styles['input']} ${styles['input-search-button']}`}
                          onClick={() => {
                            if (isKeywordBlank()) return
                            closeMenu()
                            history.push(
                              `/search?keyword=${encodeURIComponent(keyword)}`
                            )
                          }}
                          onTouchEnd={() => {
                            if (isKeywordBlank()) return
                            closeMenu()
                            history.push(
                              `/search?keyword=${encodeURIComponent(keyword)}`
                            )
                          }}
                        ></div>
                      </div>
                    )}
                    {!doorCustomize && (
                      <button
                        className={`${styles['menu-search-button']}`}
                        onClick={() => {
                          setIsOpenSearch(!isOpenSearch)
                        }}
                      ></button>
                    )}
                  </div>
                </li>
                <li
                  className={`${styles['menu-list-item-sp']} ${styles['menu-login-item']}`}
                >
                  <Link
                    to="/notifications"
                    rel="nofollow"
                    className={`${styles['menu-list-item-link-sp']} ${styles['menu-login-item']}`}
                  >
                    <img
                      src={icon_notice}
                      className={`${styles['menu-icon-image']}`}
                      alt="notice"
                    />
                  </Link>
                </li>
                <li
                  className={`${styles['menu-list-item-sp']} ${styles['menu-login-item']}`}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      setDraw(true)
                    }}
                    rel="nofollow"
                    className={`${styles['menu-icon-item-sp']} ${styles['menu-login-item']}`}
                  >
                    <img
                      src={icon_mypage}
                      className={`${styles['menu-icon-image']}`}
                      alt="mypage"
                    />
                  </a>
                </li>
                <li
                  className={`${styles['menu-list-item-sp']} ${styles['menu-logout-item']}`}
                >
                  <a
                    href=""
                    rel="nofollow"
                    className={`${styles['menu-icon-item-sp']} ${styles['menu-login-item']}`}
                  >
                    <img
                      src={icon_download2}
                      className={`${styles['menu-icon-image']}`}
                      alt="download"
                    />
                  </a>
                </li>

                {!doorCustomize && (
                  <>
                    <li
                      className={`${styles['menu-list-item-sp']} ${styles['menu-logout-item']}`}
                    >
                      <Link
                        to={{
                          pathname: '/signin',
                          state: { redirect: redirectPathAfterSignIn }
                        }}
                        className={`${styles['menu-list-item-link-sp']}`}
                        onMouseUp={closeMenu}
                        onTouchEnd={closeMenu}
                      >
                        ログイン
                      </Link>
                      <span className={`${styles['separator']}`}>/</span>
                      <Link
                        className={`${styles['menu-list-item-link-sp']}`}
                        to={{
                          pathname: '/signup',
                          state: { redirect: redirectPathAfterSignIn }
                        }}
                        onMouseUp={closeMenu}
                        onTouchEnd={closeMenu}
                      >
                        新規登録
                      </Link>
                    </li>
                    <li
                      className={`${styles['menu-list-item-sp']} ${styles['menu-logout-item']}`}
                    >
                      <a
                        className={`${styles['menu-search-sp']}`}
                        rel="nofollow"
                        onMouseUp={(e) => {
                          e.preventDefault()
                          setIsOpenSearch(!isOpenSearch)
                        }}
                        onTouchEnd={(e) => {
                          e.preventDefault()
                          setIsOpenSearch(!isOpenSearch)
                        }}
                      ></a>
                    </li>
                  </>
                )}
                {/* <!--スマホ時　menu-button-sp is-open　でXマーク--> */}
                <a
                  className={`${styles['menu-button-sp']} ${
                    isOpenSpMenu
                      ? styles['is-open']
                      : styles['menu-logout-item']
                  }`}
                  rel="nofollow"
                  onMouseUp={(e) => {
                    e.preventDefault()
                    setIsOpenSpMenu(!isOpenSpMenu)
                    onButtonClick()
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault()
                    setIsOpenSpMenu(!isOpenSpMenu)
                    onButtonClick()
                  }}
                  onClick={onButtonClick}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </ul>
            </div>
          )}
          {/* <!--スマホ時　menu-button-box is-open　で表示--> */}
          {!['/signin', '/signup'].includes(location.pathname) && (
            <div
              ref={(ref) => {
                const convert = ref as HTMLElement
                setRef(spMenuRef, convert)
              }}
              className={`${styles['menu-button-box']} ${
                isOpenSpMenu ? styles['is-open'] : ''
              }`}
            >
              <div className={`${styles['menu-button-box-inner']}`}>
                {!doorCustomize && (
                  <ul className={`${styles['menu-list']}`}>
                    <li className={`${styles['menu-list-item']}`}>
                      <Link
                        className={`${styles['menu-list-item-link']} ${styles['menu-logout-item']}`}
                        to="/opensalon"
                      >
                        サロンを開設したい方へ
                      </Link>
                    </li>
                    <li className={`${styles['menu-list-item']}`}>
                      <Link
                        className={`${styles['menu-list-item-link']} ${styles['menu-logout-item']}`}
                        to="/beginner"
                      >
                        YOORとは？
                      </Link>
                    </li>
                    <li className={`${styles['menu-list-item']}`}>
                      <Link
                        className={`${styles['menu-list-item-link']} ${styles['menu-logout-item']}`}
                        to="/faq"
                      >
                        FAQ
                      </Link>
                    </li>
                  </ul>
                )}
                <div
                  className={`${styles['menu-search-wrap']} ${styles['is-menu']}`}
                >
                  {!doorCustomize && (
                    <div
                      className={`${styles['input']} ${styles['input-search-wrap']} ${styles['is-noicon']}`}
                    >
                      <input
                        className={`${styles['input']} ${styles['input-search']}`}
                        placeholder="サロンを検索"
                        onClick={() => {
                          setIsOpenSearch(!isOpenSearch)
                        }}
                        value={keyword}
                        onChange={(e) => {
                          setKeyword(e.target.value)
                        }}
                        onKeyDown={handleSearchEnter}
                      />
                      <div
                        className={`${styles['input']} ${styles['input-search-button']}`}
                        onMouseUp={() => {
                          if (isKeywordBlank()) return
                          closeMenu()
                          history.push(
                            `/search?keyword=${encodeURIComponent(keyword)}`
                          )
                        }}
                        onTouchEnd={() => {
                          if (isKeywordBlank()) return
                          closeMenu()
                          history.push(
                            `/search?keyword=${encodeURIComponent(keyword)}`
                          )
                        }}
                      ></div>
                    </div>
                  )}
                  <button
                    className={`${styles['menu-search-button']}`}
                    onClick={() => {
                      setIsOpenSearch(!isOpenSearch)
                    }}
                  ></button>
                </div>
                <Link
                  to="/notifications"
                  rel="nofollow"
                  className={`${styles['menu-bell']} ${styles['menu-login-item']}`}
                ></Link>
                <a
                  onMouseUp={() => {
                    setDraw(true)
                  }}
                  rel="nofollow"
                  className={`${styles['menu-user']} ${styles['menu-login-item']}`}
                ></a>
                {!doorCustomize && (
                  <a
                    href="https://form.run/@yoor-document"
                    className={`${styles['menu-button']} ${styles['menu-logout-item']}`}
                    target="_blank"
                  >
                    <span className={`${styles['menu-icon-download']}`}>
                      資料ダウンロード
                    </span>
                  </a>
                )}
                <Link
                  to={{
                    pathname: '/signin',
                    state: { redirect: redirectPathAfterSignIn }
                  }}
                  className={`${styles['menu-button']} ${
                    styles['menu-logout-item']
                  } ${doorCustomize ? styles['-customize'] : ''}`}
                >
                  ログイン
                </Link>
                <Link
                  to={{
                    pathname: '/signup',
                    state: { redirect: redirectPathAfterSignIn }
                  }}
                  className={`${styles['menu-button']} ${styles['menu-button-blue']} ${styles['is-blue']} ${styles['menu-logout-item']}`}
                >
                  新規登録
                </Link>
              </div>
              {doorCustomize && (
                <DoorCustomizeLinkList>
                  {[
                    { text: 'よくある質問', href: '/faq' },
                    { text: '運営会社', href: 'https://www.fancs.com/' },
                    { text: 'ガイドライン', href: '/guideline' },
                    { text: '特定商取引法に関する表記', href: '/law' },
                    { text: 'お問い合わせ', href: '/faq' },
                    { text: '利用規約', href: '/terms' },
                    { text: '個人情報保護方針', href: 'https://www.fancs.com/privacy' },
                    { text: '個人情報の取り扱いについて(YOOR)', href: '/privacy' }
                  ].map((link) => {
                    return (
                      <DoorCustomizeLinkListItem key={link.text}>
                        <a href={link.href} target="_blank">
                          {link.text}
                        </a>
                      </DoorCustomizeLinkListItem>
                    )
                  })}
                </DoorCustomizeLinkList>
              )}
              {!doorCustomize && (
                <div className={`${styles['menu-button-box-bottom']}`}>
                  <section className={`${styles['topbanner']}`}>
                    <ul className={`${styles['topbanner-list']}`}>
                      {banners.map((b) => (
                        <li
                          className={`${styles['topbanner-list-item']}`}
                          key={b.name}
                        >
                          <p className={`${styles['topbanner-text']}`}>
                            {b.name}
                          </p>
                          <a
                            href={b.href}
                            className={`${styles['topbanner-link']}`}
                            style={{ backgroundImage: `url(${b.img})` }}
                          ></a>
                        </li>
                      ))}
                    </ul>
                  </section>
                </div>
              )}
            </div>
          )}
        </nav>
        {/* <!--menusearch is-open で表示--> */}
        {!['/signin', '/signup'].includes(location.pathname) && (
          <div
            className={`${styles['menusearch']} ${
              isOpenSearch && styles['is-open']
            }`}
          >
            <div className={`${styles['menusearch-inner']}`}>
              <div className={`${styles['menusearch-scroll']}`}>
                <div className={`${styles['menusearch-search-wrap']}`}>
                  <div
                    className={`${styles['input']} ${styles['input-search-wrap']} ${styles['is-noicon']}`}
                  >
                    <input
                      className={`${styles['input']} ${styles['input-search']}`}
                      placeholder="キーワードで検索"
                      value={keyword}
                      onChange={(e) => {
                        setKeyword(e.target.value)
                      }}
                      onKeyDown={handleSearchEnter}
                    />
                    <div
                      className={`${styles['input']} ${styles['input-search-button']}`}
                      onClick={() => {
                        if (isKeywordBlank()) return
                        closeMenu()
                        history.push(
                          `/search?keyword=${encodeURIComponent(keyword)}`
                        )
                      }}
                      onTouchEnd={() => {
                        if (isKeywordBlank()) return
                        closeMenu()
                        history.push(
                          `/search?keyword=${encodeURIComponent(keyword)}`
                        )
                      }}
                    ></div>
                  </div>
                </div>
                <article className={`${styles['menusearch-content']}`}>
                  <h3 className={`${styles['menusearch-title']}`}>
                    人気のキーワード
                  </h3>
                  <ul
                    className={`${styles['button']} ${styles['button-tag-list']}`}
                  >
                    {popularKeywords.map((k) => {
                      return (
                        <li
                          key={k.id}
                          className={`${styles['button']} ${styles['button-tag-item']}`}
                        >
                          <Link
                            to={`/keyword/${k.word_en}`}
                            onMouseUp={() => {
                              setTimeout(() => {
                                closeMenu()
                              }, 200)
                            }}
                            onTouchEnd={() => {
                              setTimeout(() => {
                                closeMenu()
                              }, 200)
                            }}
                            className={`${styles['button']} ${styles['button-tag']}`}
                          >
                            {k.word}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </article>
                {/* <article className={`${styles['menusearch-content']}`}>
                <h3 className={`${styles['menusearch-title']}`}>
                  その他のキーワード
                </h3>
                <ul
                  className={`${styles['button']} ${styles['button-tag-list']}`}
                >
                  {otherKeywords.map((k) => {
                    return (
                      <li
                        key={k.id}
                        className={`${styles['button']} ${styles['button-tag-item']}`}
                      >
                        <Link
                          to={`/keyword/${k.word_en}`}
                          className={`${styles['button']} ${styles['button-tag']}`}
                        >
                          {k.word}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </article> */}
                <div className={`${styles['menusearch-button-box']}`}>
                  <Link
                    to="/keyword"
                    onMouseUp={() => {
                      setTimeout(() => {
                        closeMenu()
                      }, 200)
                    }}
                    onTouchEnd={() => {
                      setTimeout(() => {
                        closeMenu()
                      }, 200)
                    }}
                    className={`${styles['button']} ${styles['button-blue']}`}
                  >
                    <span
                      className={`${styles['button']} ${styles['button-icon-arrow']}`}
                    >
                      すべてのキーワードを見る
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      <UserMenu
        open={draw}
        toggleDrawer={(open: boolean) =>
          (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
              event &&
              event.type === 'keydown' &&
              ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
            ) {
              return
            }

            setDraw(open)
          }}
      />
    </React.Fragment>
  )
}

const DoorCustomizeLinkList = styled.ul`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #cfcfcf;
    padding: 32px 20px 0;
    margin-top: 32px;
    gap: 24px;
  }
`
const DoorCustomizeLinkListItem = styled.li`
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 1;
  }
  a {
    @media (max-width: 480px) {
      color: #333;
    }
  }
`
