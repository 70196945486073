import React, { useEffect, useState } from 'react'
import {  useHistory } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from '@greatsumini/react-facebook-login'
import { Dialog } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import BreadCrumbs, { BreadCrumb } from 'components/common/BreadCrumbs'

import awsconfig from '../../../aws-exports'
import { getMe } from 'apis/me'
import {
  getMeUserCredential,
  MeUserCredential,
  disconnectMeUserCredential
} from 'apis/me/user_credential'

import alertModules from 'modules/alert'
import loadingModules from 'modules/loading'
import { getHasDoor } from 'modules/me'
import Facebook from 'utils/Facebook'
import Line from 'utils/Line'

import LeftMenu from 'components/account/LeftMenu'
import SettingNav from 'components/account/SettingNav'
import Twitter from 'utils/Twitter'
import { RootState } from 'store'
import Container from 'components/common/Container'
import SettingsTwoColumn from 'components/common/SettingsTwoColumn'

export default function Account() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const [inquiryId, setInquiryId] = useState('')
  const [twitterScreenName, setTwitterScreenName] = useState('')
  const [userCredential, setUserCredential] = useState<MeUserCredential | null>(
    null
  )
  const [openModal, setOpenModal] = useState(false)
  const [disconnectProvider, setDisconnectProvider] = useState<
    'google' | 'twitter' | 'line' | 'facebook' | null
  >(null)
  const [sending, setSending] = useState(false)
  const hasDoor = useSelector((state: RootState) => getHasDoor(state))
  const breadcrumbsList: BreadCrumb[] = [{ name: 'アカウント情報' }]

  const facebookDialogParamOptions = () => {
    return {
      state: Facebook.createState(),
      redirect_uri: awsconfig.facebook_connect_callback_url,
      response_type: 'token'
    }
  }

  const handleGoogleConnect = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: awsconfig.google_connect_callback_url
  })

  const handleDisconnect = async () => {
    if (!disconnectProvider) return
    setSending(true)
    try {
      const response = await disconnectMeUserCredential({
        provider: disconnectProvider
      })
      setUserCredential(response.data)
      dispatch(
        alertModules.actions.setAlert({
          text: 'アカウント連携を解除しました',
          severity: 'success'
        })
      )
    } catch (err) {
      dispatch(
        alertModules.actions.setAlert({
          text: 'アカウント連携の解除に失敗しました',
          severity: 'error'
        })
      )
    } finally {
      setOpenModal(false)
      setDisconnectProvider(null)
      setSending(false)
    }
  }

  const handleOpenModal = (provider: 'google' | 'twitter' | 'line' | 'facebook') => {
    return () => {
      setDisconnectProvider(provider)
      setOpenModal(true)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(loadingModules.actions.start())
      try {
        const me = (await getMe()).data
        const uc = (await getMeUserCredential()).data

        setEmail(me.user.email)
        setInquiryId(me.user.inquiry_id)
        setTwitterScreenName(me.user_public.twitter_screen_name)
        setUserCredential(uc)
      } catch (error) {
        // TODO error handling
      }
      dispatch(loadingModules.actions.stop())
    }
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <BreadCrumbsStyled breadcrumbsList={breadcrumbsList} className="sp-only" />
      <SettingNav type="account" hasDoor={hasDoor} />
      <Container>
        <SettingsTwoColumn
          leftContent={<LeftMenu type="account" hasDoor={hasDoor} />}
          rightContent={
            <>
              <BreadCrumbs breadcrumbsList={breadcrumbsList} className="pc-only" />
              <div className="commonHeading">
                <h2 className="togglePC">アカウント情報</h2>
              </div>
              <ul className="formList">
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        メールアドレス
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{email}</span>
                          <Button
                            onClick={() =>
                              history.push('/account/settings/email')
                            }
                          >
                            変更
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        パスワード
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span style={{ fontWeight: 'bold' }}>＊＊＊＊＊＊</span>
                          <Button
                            onClick={() =>
                              history.push('/account/settings/password')
                            }
                          >
                            変更
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        YOOR ID
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{twitterScreenName}</span>
                          <Button
                            onClick={() =>
                              history.push('/account/settings/yoorid')
                            }
                          >
                            変更
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <hr />
              <div className="commonHeading">
                <h2 className="">外部アカウント連携</h2>
                <p style={{ marginTop: '20px', letterSpacing: '-.05em' }}>
                  外部サービスのアカウントでYOORにログインすることができます。連携後もアカウントの変更や解除が可能です
                </p>
              </div>
              <ul className="formList">
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        Google
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{userCredential?.google_account_name}</span>
                          {userCredential?.google_id ? (
                            <Button onClick={handleOpenModal('google')}>
                              連携済み
                            </Button>
                          ) : (
                            <ConnectButton onClick={() => handleGoogleConnect()}>
                              未連携
                            </ConnectButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        X（Twitter）
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>
                            {userCredential?.twitter_account_name &&
                              `@${userCredential?.twitter_account_name}`}
                          </span>
                          {userCredential?.twitter_id ? (
                            <Button onClick={handleOpenModal('twitter')}>
                              連携済み
                            </Button>
                          ) : (
                            <ConnectButton
                              onClick={(e) => Twitter.handleConnect(e)}
                            >
                              未連携
                            </ConnectButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        LINE
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{userCredential?.line_account_name}</span>
                          {userCredential?.line_id ? (
                            <Button onClick={handleOpenModal('line')}>
                              連携済み
                            </Button>
                          ) : (
                            <ConnectButton onClick={() => Line.authorizeConnect()}>
                              未連携
                            </ConnectButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="formList__item">
                  <div className="form -row">
                    <div
                      className="form__item -left"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2
                        className="formHeading mb0"
                        style={{ fontSize: '16px' }}
                      >
                        Facebook
                      </h2>
                    </div>
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{userCredential?.facebook_account_name}</span>
                          {userCredential?.facebook_id ? (
                            <Button onClick={handleOpenModal('facebook')}>
                              連携済み
                            </Button>
                          ) : (
                            <FacebookLogin
                              appId={awsconfig.facebook_app_id}
                              useRedirect
                              initParams={{
                                version: 'v10.0',
                                xfbml: true
                              }}
                              dialogParams={facebookDialogParamOptions()}
                              loginOptions={{
                                return_scopes: true
                              }}
                              render={({ onClick }) => (
                                <ConnectButton onClick={onClick}>
                                  未連携
                                </ConnectButton>
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <hr />
              <div className="commonHeading">
                <h2 className="formHeading mb0" style={{ fontSize: '16px' }}>
                  問い合わせID
                </h2>
                <p style={{ marginTop: '20px' }}>
                  ※本人確認等に使用するため、問い合わせIDは外部へ公開しないようご注意ください<br />
                  ※お問い合わせの際は「問い合わせID」をご記入ください
                </p>
              </div>
              <ul className="formList">
                <li className="formList__item">
                  <div className="form -row">
                    <div className="form__item -right">
                      <div className="inputWrap">
                        <div
                          className="form-wrap"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{inquiryId}</span>
                          <CopyToClipboard
                            text={inquiryId}
                            onCopy={() => {
                              dispatch(
                                alertModules.actions.setAlert({
                                  text: 'クリップボードにコピーしました',
                                  severity: 'success'
                                })
                              )
                            }}
                          >
                            <Button>コピー</Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </>
          }
        />
      </Container>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <section className="modal ">
          <div className="modal-header no_bdr">
            <div id="alert-dialog-title" className="title">
              <p className="mt3 text-center">
                アカウント連携を解除しますか？
                <br />
                解除後、この外部アカウントにてログインができなくなります
              </p>
            </div>
            <div className="close modal-close invert">
              <Close onClick={() => setOpenModal(false)} />
            </div>
          </div>
          <div className="modal-contents btn_red">
            <div className="modal-confirmationButtons">
              <button
                onClick={() => setOpenModal(false)}
                disabled={sending}
                className="textLink is-grayedout"
              >
                キャンセル
              </button>
              <button
                onClick={handleDisconnect}
                disabled={sending}
                className="btn"
              >
                解除
              </button>
            </div>
          </div>
        </section>
      </Dialog>
    </React.Fragment>
  )
}

const BreadCrumbsStyled = styled(BreadCrumbs)`
  padding: 12px 16px 0;
`
const Button = styled.button`
  font-size: 14px;
  color: #999;
  height: 32px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  line-height: 32px;
  flex-shrink: 0;
  border: 1px solid #999;
  cursor: pointer;
  background-color: #fff;
  min-width: 110px;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
  }
`
const ConnectButton = styled(Button)`
  color: #26bfdb;
  border: 1px solid #26bfdb;
`
