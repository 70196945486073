import React from 'react'
import { Link } from 'react-router-dom'

type MenuType = 'account' | 'creditcard' | 'bank' | 'mail'

interface Props {
  type: MenuType
  hasDoor: boolean
  style?: React.CSSProperties
}

export default function LeftMenu(props: Props) {
  // ナビ内容
  const nav: Array<{
    name: string
    type: MenuType
    to: string
  }> = [
    {
      name: 'アカウント',
      type: 'account',
      to: '/account/settings'
    },
    {
      name: 'カード管理',
      type: 'creditcard',
      to: '/account/settings/creditcard'
    },
    {
      name: '通知設定',
      type: 'mail',
      to: '/account/settings/notification'
    }
  ]
  // サロン開設済の場合、口座情報リンクを表示
  if (props.hasDoor) {
    nav.splice(2, 0, {
      name: '口座情報',
      type: 'bank',
      to: '/account/settings/bank'
    })
  }

  return (
    <div className="planSettingNav">
      <p className="planSettingNavHeadinng">アカウント設定</p>
      <ul className="planSettingNavList">
        {nav.map((n, i) => (
          <li key={i} className="planSettingNavList__item">
            <Link
              rel="nofollow"
              to={n.to}
              className={n.type === props.type ? 'is-active' : ''}
              style={{ verticalAlign: 'middle' }}
            >
              {n.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
