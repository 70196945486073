"use strict";
exports.__esModule = true;
exports.logger = void 0;
var aws_exports_1 = require("../aws-exports");
exports.logger = require('simple-node-logger').createSimpleLogger();
if (aws_exports_1["default"].ENV_NAME == 'production') {
    exports.logger.setLevel('warn');
}
else if (aws_exports_1["default"].ENV_NAME == 'staging') {
    exports.logger.setLevel('debug');
}
else {
    exports.logger.setLevel('debug');
}
