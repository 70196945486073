import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import Header from 'components/Header'
import FooterView from 'components/Footer'
import styled from 'styled-components'

const useStyles: any = makeStyles((theme) => ({
  root: {},
}))

export default function ContainerPages(props: any) {
  const {
    // @ts-ignore
    component: Component,
    children,
    ...options
  } = props

  const classes = useStyles()

  const [isActive, setIsActive] = useState(false)
  const handleButtonClick = () => {
    setIsActive(true)
  }

  return (
    <>
      <Header onButtonClick={handleButtonClick} isActive={isActive} />
      <Main>
        {children}
        <Component {...options} />
      </Main>
      <FooterView />
    </>
  )
}

const Main = styled.main`
  position: relative;
  padding-top: 72px;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 1280px) {
    padding-top: 61px;
  }
  @media (max-width: 480px) {
    padding-top: 47px;
  }
`